import {mapActions} from "vuex";

export default {
  methods: {
    next(routeName) {
      this.$router.push({name: routeName})
    },
    redirect(url, newTab) {
      if (newTab) {
        window.open(url)
      } else {
        window.location.href = url
      }
    },
    ...mapActions({
      updateData: "updateData"
    }),
  }
}