import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: {
          base: '#157A7A',
          lighten1: '#069999',
          lighten2: '#6DC0A9'
        },
        logo: {
          base: '#6DC0A9',
          lighten1: "#A5CF95",
          lighten2: "#C0D23C"
        },
        grey: {
          base: '#E0E0E0',
          lighten1: '#EDEBEB',
          darken1: "#BDBCBC"
        },
        background: '#F5EFDF'
      }
    }
  }
});
