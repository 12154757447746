import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import "./styles/style.scss";
import globalMixin from "@/mixins/globalMixin";
//import * as Sentry from "@sentry/vue";

Vue.config.productionTip = false

Vue.mixin(globalMixin)

// global variable
Vue.prototype.$maxPu = process.env.VUE_APP_MAXPU
Vue.prototype.$apiMaps = process.env.VUE_APP_APIMAPS
Vue.prototype.$apiBasePath = process.env.VUE_APP_APIPATH
Vue.prototype.$gtmTag = process.env.VUE_APP_GTMTAG
/*Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRYDSN,
  environment: 'front-'+process.env.VUE_APP_ENV,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});*/

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
