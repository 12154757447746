import Vue from 'vue'
import VueCookie from 'vue-cookie'
import VueRouter from 'vue-router'

Vue.use(VueCookie)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */'@/views/Home'),
    meta:{
      title:'',
      gtag:{
        etape:'1-accueil'
      }
    }
  },
  {
    path: '/project',
    step: 1,
    name: 'Ma maison',
    component: () => import('@/views/partial/_index'),
    children: [
      {
        path: 'heating',
        name: 'Chauffage',
        component: () => import(/* webpackChunkName: "chauffage" */'@/views/project/Chauffage'),
        meta:{
          gtag:{
            etape:'2.1-projet-choix-utilisation-chauffage'
          }
        }
      },
      {
        path: 'equipements',
        name: 'Equipements',
        component: () => import(/* webpackChunkName: "equipements" */'@/views/project/Equipements'),
        meta:{
          gtag:{
            etape:'2.2-projet-choix-utilisation-equipements'
          }
        }
      },
      {
        path: 'electricity-bill',
        name: 'FactureElec',
        component: () => import(/* webpackChunkName: "factureElec" */'@/views/project/FactureElec'),
        meta:{
          gtag:{
            etape:'2.3-projet-choix-utilisation-facture-electricite'
          }
        }
      },
      {
        path: 'usage',
        name: 'Production',
        component: () => import(/* webpackChunkName: "production" */'@/views/project/Production'),
        meta:{
          gtag:{
            etape:'2.4-projet-choix-utilisation-production'
          }
        }
      },
      {
        path: 'help',
        name: 'ProductionHelp',
        component: () => import(/* webpackChunkName: "production-help" */'@/views/project/ProductionHelp'),
        meta:{
          gtag:{
            etape:'3-projet-nsp-choix-mode-production'
          }
        }
      },
      {
        path: 'hint',
        name: 'ProductionHint',
        component: () => import(/* webpackChunkName: "production-hint" */'@/views/project/ProductionHint'),
        meta:{
          gtag:{
            etape:'3-projet-vente-en-totalite'
          }
        }
      }
    ]
  },
  {
    path: '/project',
    step: 2,
    name: 'Mon projet solaire',
    component: () => import(/* webpackChunkName: "index" */'@/views/partial/_index'),
    children: [
      {
        path: 'equipment',
        name: 'Equipment',
        component: () => import(/* webpackChunkName: "equipment" */'@/views/project/Equipment'),
        meta:{
          gtag:{
            etape:'3-projet-autoconsommation-connaissance-puissance'
          }
        }
      },
      {
        path: 'help',
        name: 'EquipmentHelp',
        component: () => import(/* webpackChunkName: "equipment-help" */'@/views/project/EquipmentHelp'),
        meta:{
          gtag:{
            etape:'3-projet-autoconsommation-choix-puissance'
          }
        }
      },
      {
        path: 'hint',
        name: 'EquipmentHint',
        component: () => import(/* webpackChunkName: "equipment-hint" */'@/views/project/EquipmentHint')
      }
    ]
  },
  {
    path: '/roof',
    step: 3,
    name: 'Mon toit',
    component: () => import('@/views/partial/_index'),
    children: [
      {
        path: 'height',
        name: 'Height',
        component: () => import(/* webpackChunkName: "height" */'@/views/house/Height'),
        meta:{
          gtag:{
            etape:'4-maison-hauteur'
          }
        }
      },
      {
        path: 'tilt',
        name: 'Tilt',
        component: () => import(/* webpackChunkName: "tilt" */'@/views/house/Tilt'),
        meta:{
          gtag:{
            etape:'5-maison-inclinaison-toiture'
          }
        }
      },
      {
        path: 'cover',
        name: 'Cover',
        component: () => import(/* webpackChunkName: "cover" */'@/views/house/Cover'),
        meta:{
          gtag:{
            etape:'6-maison-couverture-toiture'
          }
        }
      },
      {
        path: 'panel',
        name: 'RoofPanelCount',
        component: () => import(/* webpackChunkName: "building-panel" */'@/views/house/Panel'),
        meta:{
          gtag:{
            etape:'7-maison-nombre-pan'
          }
        }
      },
      {
        path: 'street',
        name: 'Street',
        component: () => import(/* webpackChunkName: "street" */'@/views/address/Street'),
        meta:{
          gtag:{
            etape:'8-adresse-projet'
          }
        }
      },
      {
        path: 'panel',
        name: 'Panel',
        component: () => import(/* webpackChunkName: "panel" */'@/views/address/Panel'),
        meta:{
          gtag:{
            etape:'9-adresse-astuce-pan'
          }
        }
      }
    ]
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */'@/views/Contact'),
    meta:{
      gtag:{
        etape:'11-contact-projet'
      }
    }
  },
  {
    path: '/final',
    name: 'Final',
    component: () => import(/* webpackChunkName: "final" */'@/views/Final'),
    meta:{
      gtag:{
        etape:'12-validation-demande'
      }
    }
  },
  {
    path: '/components',
    name: 'Components',
    component: () => import(/* webpackChunkName: "components" */'@/views/Components')
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "not-found" */'@/views/PageNotFound')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {x: 0, y: 0}
  },
  routes
})

//Change title page
const DEFAULT_TITLE = 'Simulateur solaire : vos économies grâce au photovoltaïque | EnergyGo'
const DEFAULT_DESCR = 'Estimez en ligne le montant de vos économies grâce aux panneaux solaires photovoltaïques. Avec EnergyGo : produisez, consommez & revendez votre propre électricité.'
router.afterEach((to) => {
    Vue.nextTick(() => {
      if(typeof(to.meta.title) != 'undefined' && to.meta.title != null && to.meta.title != ''){
        document.title = DEFAULT_TITLE+' - '+to.meta.title
      }else{
        document.title = DEFAULT_TITLE
      }
      if(document.querySelector('meta[name="description"]') == null){
        let metaTag = document.createElement('meta');
        // Définir les attributs de la balise <meta>
        metaTag.setAttribute('name', 'description');
        metaTag.setAttribute('content', DEFAULT_DESCR);
  
        // Ajouter la balise <meta> à l'en-tête du document
        document.head.appendChild(metaTag);
      }else{
        document.querySelector('meta[name="description"]').setAttribute('content', DEFAULT_DESCR)
      }
      


      // DataLayer
      if(typeof to.meta.gtag != 'undefined'){
        var dataLayer = window.dataLayer = window.dataLayer || [];
        let type_audience = 'externe'
        let partnerName = null
        let partner = JSON.parse(localStorage.getItem('partner'))

        if(VueCookie.get('enGoSimulCookie')){
          type_audience = 'partenaire'
          partnerName = VueCookie.get('enGoSimulCookie')
        
        }else if(partner && new Date().getTime() < partner.exp){
          type_audience = 'partenaire'
          partnerName = partner.valeur
        }
        
        if(to.name !== 'Final'){
          dataLayer.push({
            "event": "PageViewGA",
            "etape": to.meta.gtag.etape,
            "partner_name" : partnerName,
            "type_audience": type_audience
          })
        }
        console.log(to.meta.gtag.etape)
      }else{
        console.log('no dataLayer')
      }
    })
})

/*routes.forEach(route => {
  console.log(`La route "${route.name}" a été configurée avec le chemin "${route.path}".`)
})*/

export default router
