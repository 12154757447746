<template>
  <v-app>
    <eg-header :class="partnerClass"
        v-if="displayHeader && !isLastPage"
    />
    <v-main :class="partnerClass">
      <v-container
          :class="{'mb-6': true, 'mb-md-0': true, 'eg-container__full-width-xs': fullWidthContainer}"
          fill-height
      >
        <transition name="fade" mode="out-in">
          <router-view :key="$route.name"/>
        </transition>
      </v-container>
    </v-main>
    <eg-footer
        :is-first-page="!displayHeader"
        :is-last-page="isLastPage"
        :class="partnerClass"
    />
  </v-app>
</template>

<script>
import EgFooter from '@/components/layout/EgFooter'
import EgHeader from '@/components/layout/EgHeader'
import axios from 'axios'
import { mapActions } from 'vuex'
import {mapGetters} from 'vuex'
import themeServices from '@/services/themeServices'

export default {

  components: {
    EgHeader,
    EgFooter
  },
  data(){
    return {
      token: null,
      dataSimu: null,
      partnerClass: 'EnergyGoClass',
    }
  },
  beforeMount(){
      //Google Tag Manager
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://metrics.energy-go.fr/6e5rd96ujcyghdd.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', process.env.VUE_APP_GTMTAG);
  },
  mounted() {
      setTimeout(function() {
        //didomi
        window.gdprAppliesGlobally=false;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
        else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
        var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
        if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
        var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("507c0b8e-24f3-482b-8aff-4d22c00927ad")})();
      }, 800);
      localStorage.setItem('partners', JSON.stringify(this.partnerList))
      this.setDepartement()
      this.setSimulation()
      this.setPartner()
      this.setPartnerTemplate()
  },
  computed: {
    ...mapGetters({
      partnerList: 'getPartnerList',
    }),

    displayHeader() {
      return this.$route.name !== 'Home'
    },
    isLastPage() {
      return this.$route.name === 'Final'
    },
    fullWidthContainer() {
      return this.$route.name === 'Panel'
    }
  },
  methods: {
    ...mapActions({
      resetData: 'resetData',
      setlocalStorageData: 'setlocalStorageData'
    }),

    setPartnerTemplate() {
        themeServices.setTheme()
        this.partnerClass = themeServices.getThemeClass()
    },

    setPartner() {
      let parametersVals = ''
      let urlParams = new URLSearchParams(window.location.search)
      if(typeof urlParams.get('partner') != 'undefined' && urlParams.get('partner') != null){
        let partner = urlParams.get('partner').replace(/\//g, '')
        let port = ''
        if(window.location.port){
          port = ':'+window.location.port
        }
        let dateExpire = new Date().getTime() + 60 * 60 * 1000; // 1 heure
        let dataToStorage = {
          valeur:partner,
          exp:dateExpire
        }
        if(urlParams.has('duplicate')){
          parametersVals = '?duplicate='+urlParams.get('duplicate')
        }
        localStorage.setItem('partner', JSON.stringify(dataToStorage))
        window.location.href = '//'+window.location.hostname+port+parametersVals
      }
    },

    setSimulation() {
      let urlParams = new URLSearchParams(window.location.search)
      if(urlParams.has('duplicate')){
        let duplicateVal = urlParams.get('duplicate')
        if(duplicateVal.charAt(duplicateVal.length - 1) === "/"){
          duplicateVal = duplicateVal.substring(0, duplicateVal.length - 1)
        }
        this.token = duplicateVal
        this.getSimulationBytoken()
        this.populateSimulation()
      }else{
        this.resetData()
        localStorage.removeItem('simulation')
      }
    },
    populateSimulation(){
      const self = this
      setTimeout(function(){
        let isSimulation = localStorage.getItem('simulation')
        if(isSimulation){
          let simu = JSON.parse(isSimulation)
          simu.setMap = false
          localStorage.setItem('simulation', JSON.stringify(simu))
          self.resetData()
          self.setlocalStorageData()
        }
      },500)
    },
    getSimulationBytoken(){
      const url = process.env.VUE_APP_APIPATH+'/api/simulator/simulation/'+this.token
      const config = {
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
      axios(config)
      .then(function (response) {
        localStorage.setItem('simulation', JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log('error get simulation by token')
        console.log(error)
      })
    },
    setDepartement() {
      const url = process.env.VUE_APP_APIPATH+'/api/departement/photovoltaique'
      const config = {
        method: 'get',
        url: url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      }
      axios(config)
      .then(function (response) {
        localStorage.departement = response.data
      })
      .catch(function (error) {
        console.log('error')
        console.log(error)
      })
    }
  }
}
</script>
<style lang="scss">
  
</style>
