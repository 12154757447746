import { render, staticRenderFns } from "./EgLogo.vue?vue&type=template&id=1e1eb3c0&scoped=true"
import script from "./EgLogo.vue?vue&type=script&lang=js"
export * from "./EgLogo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e1eb3c0",
  null
  
)

export default component.exports