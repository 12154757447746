<template>
  <img
      :src="require('@/assets/images/Logo_'+imgPath)"
      alt="logo EnergyGo"
      class="engo-logo BrandEnergyGo"
  />
</template>

<script>
import themeServices from '@/services/themeServices'

export default {
  name: "eg-logo",
  data(){
    return {
      imgPath: 'EnergyGo.png'
    }
  },

  mounted() {
    this.setThemeLogo()
  },

  methods: {
    setThemeLogo(){
      let logo = themeServices.getThemeLogo()
      if(logo != null){
        this.imgPath = logo
      }else{
        setTimeout(() => {
          document.querySelector('.engo-logo').src = require('@/assets/images/Logo_'+this.imgPath)
        }, 100, themeServices)
      }
    }
  }
}
</script>

<style scoped>

</style>