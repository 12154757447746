<template>
  <div class="eg-footer">
    <v-footer
        v-if="!isLastPage && !isFirstPage"
        color="background"
        class="bg-grey"
        padless
    >
      <v-container>
        <div class="row
        flex-column flex-md-row
        align-center justify-center
        pt-md-4 pb-md-5"
        >
          <div
              class="d-flex
              flex-column
              col-12 col-md-12 col-lg-12
              align-center mb-10 mb-md-0"
          >
           <p class="mb-0 pb-1 txt-center" style="font-size: 12px;">Ces informations sont nécessaires pour la réalisation de votre simulation gratuite. Pour plus d’informations sur la façon dont EnergyGo traite vos données
personnelles, veuillez consulter notre <a :href=this.linkPolConf target="_blank">politique de confidentialité</a>. Vous pouvez aussi consulter les <a :href=this.linkMentions target="_blank">Mentions légales</a> et notre <a href="javascript:Didomi.preferences.show()">Gestion des cookies</a>.</p>
          </div>
        </div>
      </v-container>
    </v-footer>

    <v-footer
        v-if="isFirstPage || isLastPage"
        color="primary"
        class="bg-grey homepageFooter"
        padless
    >
      <v-container>
        <div
            class="row pt-2 pb-4 pt-sm-1 pb-sm-1"
        >
          <div class="col-12 d-flex justify-content-end">
            <a
                class="eg-footer-link font-size-12"
                target="_blank"
                :href=this.linkMentions
            >
              Mentions légales
            </a>
            <a
                class="eg-footer-link ml-10 font-size-12"
                target="_blank"
                :href=this.linkPolConf
            >
              Politique de confidentialité
            </a>
            <a
              class="eg-footer-link ml-10 font-size-12"
              target="_blank"
              :href=this.linkPolCookies
            >
              Politique des cookies
            </a>
            <a
              class="eg-footer-link ml-10 font-size-12"
              href="javascript:Didomi.preferences.show()"
            >
              Gestion des cookies
            </a>
          </div>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script>

export default {
  name: "eg-footer",
  components: {
  },
  data() {
    return {
      linkPolConf: 'https://www.energy-go.fr/politique-de-confidentialite/',
      linkMentions: 'https://www.energy-go.fr/mentions-legales/',
      linkPolCookies: 'https://www.energy-go.fr/politique-des-cookies/'
    }
  },
  props: {
    isFirstPage: {
      type: Boolean,
      default: false
    },
    isLastPage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.eg-footer {
  
  .homepageFooter{
    position: relative;
    z-index: 50;
    bottom: 0;
    width: 100%;
  }
  .hide{
    display: none;
  }
  .eg-footer-bottom-bar {
    width: 100%;
  }

  .v-footer{
    height: 80px;
    padding: 0;
    margin: 0;
  }

  p{
    color: #2da49e !important;
    line-height: 1em;
  }

  .eg-footer-link {
    color: #2da49e !important;
    border: unset;
    font-weight: 500;
    font-size: 14px;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-footer{
    padding-top: 10px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    p{
      line-height: 1.5em !important;
    }
    a{
      line-height: 1.5em !important;
    }
  }
  .eg-footer .v-footer.homepageFooter{
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
}
</style>