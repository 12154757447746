<template>
  <v-container>
    
    <div v-if="this.$cookie.get('enGoSimulCookie')"
      fluid
      >
      <div class="row pt-2 pb-2 pl-5 adminContainer">
        <div class="col-6">Connecté en tant que <a :href="this.$apiBasePath+'/admin'" target="_blank">{{ this.$cookie.get('enGoSimulCookie') }}</a></div>
        <div class="col-6 text-align-right pr-5"><a :href="this.$apiBasePath+'/admin'" target="_blank">Dashboard</a> | <a :href="this.$apiBasePath+'/logout'" target="_blank">Déconnexion</a></div>
      </div>
    </div>
    <div
        class="eg-header row justify-space-between pt-9 pb-md-7 align-center"
    >
      <eg-logo class="eg-logo"/>
      <div
          v-if="!hideBackButton"
          class="col-3"
      >
        <div
            v-if="!hideBackButtonStep1"
        >
        </div>
      </div>
      <div :class="{'col-9': true, 'd-flex': true, 'justify-end': !hideBackButton}">
        <eg-stepper
            v-if="step > 0"
            :step-name="stepName"
            :step="step"
            :step-value="stepValue"
        />
      </div>

      <div class="d-md-none col-12 mt-8 mb-2 titleFrame">
        <span class="font-size-18">{{stepName}}</span>
      </div>
    </div>
  </v-container>

</template>

<script>
import EgStepper from "@/components/special/EgStepper";
import EgLogo from "@/components/special/EgLogo";

import Vue from 'vue'
import VueCookie from 'vue-cookie'

Vue.use(VueCookie)

export default {
  name: "eg-header",
  components: {
    EgStepper,
    EgLogo
  },
  props: {
    /*showAdminBar: {
      type: Boolean,
      default: false
    },*/
    hideBackButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      hideBackButtonStep1:false,
      step: 0,
      stepValue: 0,
      stepName: ""
    }
  },
  mounted() {
  },
  methods: {
    hideBackButtonFunction() {
      if(this.$cookie.get('enGoSimulCookie') != null && this.$route.name == 'Production'){
        this.hideBackButtonStep1 = true
      }else{
        this.hideBackButtonStep1 = false
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.hideBackButtonFunction()
        const currentRoute = this.$route;
        const routes = this.$router.options.routes;
        for (let i = 0; i < routes.length; i++) {
          let route = routes[i];
          if (!route.step || !route.children) {
            continue;
          }
          let totalChildren = route.children.length;
          for (let j = 0; j < totalChildren; j++) {
            let child = route.children[j];
            if (child.name === currentRoute.name) {
              this.step = route.step;
              this.stepValue = (((j + 1) / totalChildren) * 100);
              this.stepName = route.name;
              return;
            }

            if (!child.children) {
              continue;
            }

            let totalSubChildren = child.children.length;
            for (let k = 0; k < totalSubChildren; k++) {
              let subChild = child.children[k];
              if (subChild.name === currentRoute.name) {
                this.step = route.step;
                this.stepValue = (((j + 1) / totalChildren) * 100);
                this.stepName = route.name;
                return;
              }
            }
          }
        }
        this.step = 0;
      }
    }
  }
}
</script>

<style lang="scss">
  .text-align-right{
    text-align: right;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .eg-header.pt-9{
      padding-top: 10px !important;
      padding-bottom: 20px;
    }
    .engo-logo{
      width: 120px !important;
    }
    .eg-home .engo-logo{
      width: 220px !important;
    }
    .titleFrame{
      position: absolute;
      right: 95px !important;
      top: -19px;
      text-align: right;
      width: 155px;
      span{
        font-size: 15px;
      }
    }
  }
</style>