<template>
  <div class="eg-stepper d-flex align-center">
    <span class="mr-5 d-none d-md-block font-size-18">{{stepName}}</span>

    <eg-progress-circle
        v-for="n in nbMax"
        :key="n"
        :class="{'mr-2': n < nbMax}"
        :classes="stepClasses[n-1]"
        :step="n"
        :value="getStepValue(n)"
        :is-step-passed="step > n"
    />

  </div>
</template>

<script>
import EgProgressCircle from "@/components/base/EgProgressCircle"

export default {
  name: "eg-stepper",
  components: {
    EgProgressCircle
  },
  props: {
    stepName: {
      type: String,
      required: true
    },
    stepValue: {
      type: Number,
      required: true
    },
    step: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      stepClasses: ["stepLightGreen", "stepGreen", "stepDarkGreen"],
      //stepClasses: ["", "text--lighten-1", "text--lighten-2"],
      nbMax: 3
    }
  },
  methods: {
    getStepValue(step) {
      this.hideTitleStepper(step)
      if (this.step < step) {
        return 0;
      } else if (this.step > step) {
        return 100;
      } else {
        return this.stepValue
      }
    },
    hideTitleStepper(step){
      let titleblock = document.querySelector('.titleFrame')
      if(titleblock != null){
        if(step == 3 && this.stepValue == 100){
          titleblock.classList.add('hide')
        }else{
          titleblock.classList.remove('hide')
        }
      }

    }
  }
}
</script>

<style lang="scss">
  .v-application .logo--text.stepLightGreen{
    color: #b0ddde !important;
    caret-color: #b0ddde !important;
  }
  .v-application .logo--text.stepGreen{
    color: #5cbabb !important;
    caret-color: #5cbabb !important;
  }
  .v-application .logo--text.stepDarkGreen{
    color: #0c999a !important;
    caret-color: #0c999a !important;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .eg-stepper{
      position: absolute;
      right: 0px;
      top: 12px;
      transform: scale(.7);
      transform-origin: right top;
    }
  }
</style>