export function setFavicon(template) {
    let url = '/favicon.ico'
    if(template === 'sol-et-moi'){
        url = '/favicon-hes.ico'
    }
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = url;
}
