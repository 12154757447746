
import { setFavicon } from '@/utils/favicon'

export default {
    currentTheme: 'ego',
    currentThemeClass: '',
    currentThemeFilter: false,
    currentThemeLogo: null,
  
    setTheme() {
        let partner = JSON.parse(localStorage.getItem('partner'))
        let partners = JSON.parse(localStorage.getItem('partners'))
        if(partner !== null){
            for(let i = 0; i < partners.length; i++){
                if(partner.valeur === partners[i].slug){
                    this.currentTheme = partner.valeur
                    this.currentThemeClass = partners[i].class
                    this.currentThemeLogo = partners[i].logo
                    setFavicon(partner.valeur)
                }
            }
        }
    },

    getThemeLogo(){
        return this.currentThemeLogo
    },

    getThemeFilter(){
        return this.currentThemeFilter
    },

    getThemeClass(){
        return this.currentThemeClass
    },
      
    getTheme(){
        return this.currentTheme
    }
}