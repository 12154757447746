import Vue from 'vue'
import Vuex from 'vuex'
import { merge } from "lodash"
import { Loader } from "@googlemaps/js-api-loader";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {
      partnerList:[
        {slug:"homeserve", class:"HomeserveClass", logo:"Logo_Homeserve.png"},    
        {slug:"sol-et-moi", class:"sol-et-moiClass", logo:"sol-et-moi.svg"}
      ],
      chauffage: null,
      equipements: null,
      factureElec:null,
      production: null,
      knownPower: null,
      power: null,
      otherPower: null,
      buildingHeight: null,
      buildingCover: "",
      buildingTilt: null,
      roofPanelCount: null,
      markerPoints: null,
      roofPanels: [
        { width: null, length: null, points: null },
        { width: null, length: null, points: null },
        { width: null, length: null, points: null },
        { width: null, length: null, points: null }
      ],
      roofPanelsCanvas: "",
      address: {
        full: "",
        street: "",
        zipCode: "",
        city: ""
      },
      coordinates: {
        lat: 46,
        lng: 2
      },
      contact: {
        firstName: "",
        lastName: "",
        mail: "",
        phone: "",
        dolibarrUser: "",
        utm_source: "",
        utm_medium: "",
        utm_campaign: "",
        utm_adset: "",
        utm_content: "",
        utm_term: "",
        partner: "",
        callback: {
          day: null,
          hour: null
        },
        knew: {
          choice: null,
          sponsorEmail: "",
          proName: "",
          other: ""
        }
      }
    },
    googleApi: null
  },
  getters: {
    getData: state => state.data,
    getChauffage: state => state.data.chauffage,
    getEquipements: state => state.data.equipements,
    getFactureElec: state => state.data.factureElec,
    getProduction: state => state.data.production,
    getKnownPower: state => state.data.knownPower,
    getPartnerList: state => state.data.partnerList,
    getPower: state => state.data.power,
    getOtherPower: state => state.data.otherPower,
    getBuildingHeight: state => state.data.buildingHeight,
    getBuildingCover: state => state.data.buildingCover,
    getBuildingTilt: state => state.data.buildingTilt,
    getRoofPanelCount: state => state.data.roofPanelCount,
    getMarkerPoints: state => state.data.markerPoints,
    getRoofPanel: (state) => (index) => {
      let routePanel = state.data.roofPanels[index]
      if (!routePanel) {
        routePanel = {
          width: null,
          length: null
        }
      }
      return routePanel
    },
    getRoofPanels: state => state.data.roofPanels,
    getAddress: state => state.data.address,
    getContact: state => state.data.contact,
    getCoordinates: state => state.data.coordinates,
    getApiData: state => {
      let numSolarPanelWanted = '0';
      if (state.data.knownPower) {
        if (state.data.power && state.data.power > '0') {
          numSolarPanelWanted = state.data.power
        } else if (state.data.otherPower && state.data.otherPower > '0') {
          numSolarPanelWanted = state.data.otherPower
        }
      }
      let data = {
        chauffage:state.data.chauffage,             //step1
        equipements:state.data.equipements,         //step2
        factureElec:state.data.factureElec,         //step3
        production:state.data.production,           //step4
        knownPower:state.data.knownPower,           //step5
        numSolarPanelWanted: numSolarPanelWanted,
        numFloor: state.data.buildingHeight,
        numRoofPanels: state.data.roofPanelCount,
        markerPoints: state.data.markerPoints,
        inclinaison: state.data.buildingTilt,
        cover: state.data.buildingCover,
        addressCity: state.data.address.city,
        addressFull: state.data.address.full,
        addressStreet: state.data.address.street,
        addressZipCode: state.data.address.zipCode,
        //contactCallbackDay: state.data.contact.callback.day,
        //contactCallbackHour: state.data.contact.callback.hour,
        contactFirstName: state.data.contact.firstName,
        contactConsentEnergyConseil:state.data.contact.consentEnergyConseil,
        contactConsentCommercial:state.data.contact.consentCommercial,
        contactEmail: state.data.contact.mail,
        contactPhoneNumber: state.data.contact.phone,
        contactLastName: state.data.contact.lastName,
        contactDolibarrUser:state.data.contact.dolibarrUser,

        utm_source:state.data.contact.utm_source,
        utm_medium:state.data.contact.utm_medium,
        utm_campaign:state.data.contact.utm_campaign,
        utm_adset:state.data.contact.utm_adset,
        utm_content:state.data.contact.utm_content,
        utm_term:state.data.contact.utm_term,
        partner:state.data.contact.partner,
        
        //contactKnewOther: state.data.contact.knew.other,
        //contactKnewProName: state.data.contact.knew.proName,
        //contactKnewSponsorEmail: state.data.contact.knew.sponsorEmail,
        //contactKnewChoice: state.data.contact.knew.choice,
        roofPanelsCanvas: state.data.roofPanelsCanvas
      }
      console.log(data)
      let i = 0;
      state.data.roofPanels.forEach(panel => {
        if (panel.points !== null) {
          i++
          let { length, width, azimuth } = {
            length: "roofPanels" + i + "Length",
            width: "roofPanels" + i + "Width",
            azimuth: "roofPanels" + i + "Azimuth",
          }
          let panelObject = {}
          panelObject[length] = panel.length
          panelObject[width] = panel.width
          panelObject[azimuth] = panel.azimuth

          data = merge(data, panelObject)
        }
      })

      return data
    }
  },
  mutations: {
    updateData(state, data) {
      merge(state.data, data)
    },
    setGoogleApi(state, data) {
      state.googleApi = data
    },
    updateRoofPanel(state, { data, index }) {
      let roofPanel = state.data.roofPanels[index];
      if (roofPanel) {
        merge(state.data.roofPanels[index], data)
      } else {
        state.data.roofPanels[index] = data
      }
    },
    resetRoofPanels(state) {
      state.data.roofPanels = [
        { width: null, length: null, points: null },
        { width: null, length: null, points: null },
        { width: null, length: null, points: null },
        { width: null, length: null, points: null }
      ]
      state.data.roofPanelsCanvas = ""
    },
    resetData(state) {
      state.data = {
        chauffage: null,
        equipements: null,
        factureElec: null,
        production: null,
        power: null,
        knownPower: null,
        otherPower: null,
        buildingHeight: null,
        buildingCover: "",
        buildingTilt: null,
        roofPanelCount: null,
        markerPoints: null,
        roofPanels: [
          { width: null, length: null, points: null },
          { width: null, length: null, points: null },
          { width: null, length: null, points: null },
          { width: null, length: null, points: null }
        ],
        roofPanelsCanvas: "",
        address: {
          full: "",
          street: "",
          zipCode: "",
          city: ""
        },
        coordinates: {
          lat: 46,
          lng: 2
        },
        contact: {
          firstName: "",
          lastName: "",
          mail: "",
          phone: "",
          dolibarrUser:"",
          utm_source: "",
          utm_medium: "",
          utm_campaign: "",
          utm_adset: "",
          utm_content: "",
          utm_term: "",
          partner: "",
          callback: {
            day: null,
            hour: null
          },
          knew: {
            choice: null,
            sponsorEmail: "",
            proName: "",
            other: ""
          }
        }
      }
    },
    setRoofPanel(state){
      let isSimulation = localStorage.getItem('simulation')
      if(isSimulation){
        let simu = JSON.parse(isSimulation)
        state.data.roofPanels = [
          { width: simu.roofPanels_1_width, length: simu.roofPanels_1_length, points: simu.roofPanels_1_points },
          { width: simu.roofPanels_2_width, length: simu.roofPanels_2_length, points: simu.roofPanels_2_points },
          { width: simu.roofPanels_3_width, length: simu.roofPanels_3_length, points: simu.roofPanels_3_points },
          { width: simu.roofPanels_4_width, length: simu.roofPanels_4_length, points: simu.roofPanels_4_points }
        ]
        console.log(simu.roofPanelsCanvas)
        state.data.roofPanelsCanvas = simu.roofPanelsCanvas
      }
    },
    setlocalStorageData(state) {
      let isSimulation = localStorage.getItem('simulation')
      if(isSimulation){
        let simu = JSON.parse(isSimulation)
        if(typeof simu.power == 'undefined')
          simu.power = simu.numSolarPanelWanted
        //if(typeof simu.otherPower == 'undefined')
          //simu.otherPower = simu.numSolarPanelWanted
        //if(typeof simu.knownPower == 'undefined')
          //simu.knownPower = simu.numSolarPanelWanted
        state.data = {
          chauffage: simu.chauffage,
          equipements: simu.equipements,
          factureElec: simu.factureElec,
          production: simu.production,
          power: simu.power,
          knownPower: simu.knownPower,
          otherPower: simu.otherPower,
          buildingHeight: simu.numFloor,
          buildingCover: simu.cover,
          buildingTilt: simu.inclinaison,
          roofPanelCount: simu.numFloor,
          numSolarPanelWanted: simu.numSolarPanelWanted,
          roofPanels: [
            { width: simu.roofPanels_1_width, length: simu.roofPanels_1_length, points: simu.roofPanels_1_points },
            { width: simu.roofPanels_2_width, length: simu.roofPanels_2_length, points: simu.roofPanels_2_points },
            { width: simu.roofPanels_3_width, length: simu.roofPanels_3_length, points: simu.roofPanels_3_points },
            { width: simu.roofPanels_4_width, length: simu.roofPanels_4_length, points: simu.roofPanels_4_points }
          ],
          roofPanelsCanvas: simu.roofPanelsCanvas,
          address: {
            full: simu.address_full,
            street: simu.address_street,
            zipCode: simu.address_zipCode,
            city: simu.address_city
          },
          coordinates: {
            lat: 46,
            lng: 2
          },
          contact: {
            firstName: simu.contact_firstName,
            lastName: simu.contact_lastName,
            mail: simu.contact_email,
            phone: simu.contact_phoneNumber,
            dolibarrUser: simu.contact_dolibarrUser,
            utm_source: simu.utm_source,
            utm_medium: simu.utm_medium,
            utm_campaign: simu.utm_campaign,
            utm_adset: simu.utm_adset,
            utm_content: simu.utm_content,
            utm_term: simu.utm_term,
            partner: simu.utm_term
          }
        }
      }
    }
  },
  actions: {
    updateData(context, data) {
      context.commit('updateData', data)
    },
    initGoogleApi(context) {
      if (context.state.googleApi === null) {
        console.log('init google map api');
        const loader = new Loader({
          libraries: ['drawing', 'geometry', 'places'],
          apiKey: process.env.VUE_APP_APIMAPS,
        });
        context.commit('setGoogleApi', loader.load());
        return loader.load();
      }
      return context.state.googleApi;
    },
    updateRoofPanel(context, { data, index }) {
      context.commit('updateRoofPanel', { data, index })
    },
    resetRoofPanel(context) {
      context.commit('resetRoofPanels')
    },
    resetData(context) {
      context.commit('resetData')
    },
    setlocalStorageData(context) {
      context.commit('setlocalStorageData')
    },
    setRoofPanel(context) {
      context.commit('setRoofPanel')
    }
  },
  modules: {}
})
