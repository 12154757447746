<template>
  <v-progress-circular
      :class="'eg-progress-circle ' + classes + (isStepPassed ? ' eg-progress__full' : '')"
      :rotate="-90"
      :size="36"
      :width="8"
      :value="value"
      :color="value > 0 ? color : $vuetify.theme.themes.light.background"
  >
    <span v-if="value > 0">{{ step }}</span>
  </v-progress-circular>
</template>

<script>
export default {
  name: "eg-progress-circle",
  props: {
    value: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: "logo"
    },
    // the displayed step in center of progress
    step: {
      type: Number,
      required: true
    },
    // can be used to add text color variants
    classes: {
      type: String,
      default: ''
    },
    isStepPassed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.eg-progress-circle {
  
  .v-progress-circular__info {
    font-size: 14px;
  }

  &:not(.eg-progress__full) {
    .v-progress-circular__overlay {
      stroke: white;
    }
  }

  &:nth-child(2){
    .v-progress-circular__underlay{
      stroke: #eee;
    }
    .v-progress-circular__overlay {
      stroke: currentColor;
    }
    &[aria-valuenow="100"]{
      .v-progress-circular__underlay{
        stroke: currentColor; 
      }
    }
  }

  &:nth-child(3){
    .v-progress-circular__underlay{
      stroke: #eee;
    }
    .v-progress-circular__overlay {
      stroke: #5cbabb;
    }
    &[aria-valuenow="100"]{
      .v-progress-circular__underlay{
        stroke: #5cbabb; 
      }
    }
  }

  &:nth-child(4){
    .v-progress-circular__underlay{
      stroke: #eee;
    }
    .v-progress-circular__overlay {
      stroke: #0c999a;
    }
    &[aria-valuenow="100"]{
      .v-progress-circular__underlay{
        stroke: #0c999a; 
      }
    }
  }



  .v-progress-circular__overlay {
    stroke-width: 6
  }
}

</style>